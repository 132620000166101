import { replaceRedBullWhitespaces } from "./replaceRedBullWhitespaces";

function t(translation?: string, defaultValue?: string): string {
  if (translation) {
    return replaceRedBullWhitespaces(translation);
  }
  if (!translation && defaultValue) {
    return replaceRedBullWhitespaces(defaultValue);
  }
  return "No translation available";
}

function translateWithPlaceholders(
  translation: string,
  replacements: Record<string, string>,
): string {
  const placeholderNames = Object.keys(replacements);
  return placeholderNames.reduce(
    (textWithPlaceholders, placeholderName) =>
      textWithPlaceholders.replace(
        `{{${placeholderName}}}`,
        replacements[placeholderName],
      ),
    translation,
  );
}

export default function template(
  translation: string,
  fallback?: string,
  replacements?: Record<string, string>,
): string {
  const result = t(translation, fallback);
  if (replacements) {
    return replaceRedBullWhitespaces(
      translateWithPlaceholders(result, replacements) ?? fallback ?? result,
    );
  }
  return result;
}
