import classNamesBind from "classnames/bind";
import classNames from "classnames";
import styles from "./prose.module.css";

const cx = classNamesBind.bind(styles);

export const Prose = ({
  children,
  className,
  appearance = "dark",
}: {
  children: React.ReactNode;
  className?: string;
  appearance?: "light" | "dark";
}) => {
  return (
    <div
      className={classNames(className, cx("container"))}
      data-appearance={appearance}
    >
      {children}
    </div>
  );
};
