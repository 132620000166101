interface Dimensions {
  width: number;
  height: number;
}

// https://www.storyblok.com/faq/image-dimensions-assets-js

/**
 * Example of how to bottom crop something:
 *
 * const canImageDimensions = getStoryblokImageDimension(canImageUrl);
 * const canImageFocalX = Math.ceil(canImageDimensions.width / 2);
 * const canImageFocalY = canImageDimensions.height;
 * const canImageFocal: ImageOptions["focal"] = `${canImageFocalX - 1}x${canImageFocalY - 1}:${canImageFocalX}x${canImageFocalY}`; //X1xY1:X2xY2
 *
 */

export const getStoryblokImageDimension = (
  url?: string,
): Dimensions | undefined => {
  /**
   * Extract the image dimensions from URL like:
   * "//a.storyblok.com/f/51376/664x488/f4f9d1769c/visual-editor-features.jpg"
   */

  if (!url) return undefined;

  const parts = url.split("/");
  const size = parts[5]?.split("x");

  const width = size?.[0] ? parseInt(size[0]) : 0;
  const height = size?.[1] ? parseInt(size[1]) : 0;

  // If dimensions couldn't be extracted, return undefined
  if (!width || !height) return undefined;

  return { width, height };
};
