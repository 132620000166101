import { getContentfulImageUrl } from "./images/getContentfulImageUrl";
import { getStoryblokImageUrl } from "./images/getStoryblokImageUrl";
import { getRbImageUrl } from "./images/getRbImageUrl";
import { getStoryblokImageDimension } from "./images/getStoryblokImageDimension";

/**
 * These sizes are more-or-less arbitrary, they were chosen because they
 * represent some typical viewport widths according to:
 * https://gs.statcounter.com/screen-resolution-stats
 *
 * More options results in more granular performance optimisation, but at the
 * cost of cache-hit probability.
 */
export const TYPICAL_VIEWPORT_WIDTHS = [
  320, 480, 768, 1024, 1200, 1366, 1440, 1536, 1920, 2560,
];

export const responsiveImageHelper = {
  createCrepoSrcSet: (source: string, localeString: string) => {
    return TYPICAL_VIEWPORT_WIDTHS.map((width) => {
      const resizedSrc = getRbImageUrl(source, { width }, localeString);

      return `${resizedSrc} ${width}w`;
    }).join(", ");
  },
  createContentfulSrcSet: (source: string) => {
    /**
     * See:
     * https://cloudinary.com/documentation/transformation_reference
     */
    return TYPICAL_VIEWPORT_WIDTHS.map((width) => {
      const resizedSrc = getContentfulImageUrl(source, { width });

      return `${resizedSrc} ${width}w`;
    }).join(", ");
  },
  createStoryblokSrcSet: (source: string) => {
    // get original image size, to check for Storyblok upscaling
    const sourceDimensions = getStoryblokImageDimension(source);

    return TYPICAL_VIEWPORT_WIDTHS.map((width) => {
      // If source < viewport width, then don't create an upscaled image.
      // TODO: We currently return the same image for multiple `srcset` sizes, should we exit the loop?
      if (sourceDimensions?.width && width > sourceDimensions.width) {
        const resizedSrc = getStoryblokImageUrl(source);

        return `${resizedSrc} ${width}w`; // Use original image if requested width is larger
      }

      const resizedSrc = getStoryblokImageUrl(source, { width });

      return `${resizedSrc} ${width}w`;
    }).join(", ");
  },
};
