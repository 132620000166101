import { SubAssetType } from "./getSubAssetType";
import { dataLayer } from "./dataLayer";
import { appendHostnameToRelativeUrl } from "./appendHostnameToRelativeUrl";
import { env } from "./env";

/**
 * Often we are checking if the environment has a GTM container inside each component,
 * Maybe we should move all checks here?
 *
 * if (!env.NEXT_PUBLIC_HAS_GTM_CONTAINER) {
 *   return null;
 * }
 */

export function startAnalytics({
  gtmSource,
  title,
  route,
  localeString,
  subAssetType,
}: {
  gtmSource: string | null;
  title: string | null;
  route: string;
  localeString: string;
  subAssetType: SubAssetType | null;
}) {
  dataLayer.push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js",
  });

  const [country = null, language = null] = localeString.includes("-")
    ? localeString.split("-")
    : [];

  dataLayer.push({
    brand: "redbull.com",
    environment: "on-site",
    event: "init-meta",
    websiteVersion: "rbx",
    language,
    locale: country === "gb" ? "uk" : country,
    propertyName: `redbull.com - ${country === "gb" ? "uk" : country}`,
    siteType: "website",
    breakpoint: "unspecified",
    subAssetType,
    template: "integration",
    source: gtmSource || "product/company",
    adPos: "unspecified",
    assetID: "unspecified",
    chapter: "unspecified",
    contentCategory: "unspecified",
    contentLanguageLocale: "unspecified",
    contentNumberOfPhotos: "unspecified",
    contentNumberOfRecirculationModules: "unspecified",
    contentNumberOfSocialEmbeddings: "unspecified",
    contentNumberofVideos: "unspecified",
    contentNumberofWords: "unspecified",
    createdLanguageLocale: "unspecified",
    createdVsTranslated: "unspecified",
    disciplinesAerial: "unspecified",
    disciplinesAll: "unspecified",
    disciplinesBike: "unspecified",
    disciplinesESports: "unspecified",
    disciplinesMotorsports: "unspecified",
    disciplinesOtherSports: "unspecified",
    disciplinesPseudoGroup: "unspecified",
    "disciplinesPseudoGroup-combined": "unspecified",
    disciplinesSports: "unspecified",
    disciplinesWaterSports: "unspecified",
    disciplinesWinterSports: "unspecified",
    frameLanguageLocale: "unspecified",
    geoLocation: "unspecified",
    initiallyPrerendered: "unspecified",
    label: "unspecified",
    labelGroup: "unspecified",
    localCustomGrouping: "unspecified",
    loggedInStatus: "unspecified",
    masterID: "unspecified",
    masterIDRB2: "unspecified",
    originalPublishingDate: "unspecified",
    pageLoadTime: "unspecified",
    promotionclicktype: "unspecified",
    promotionmodule: "unspecified",
    promotionposition: "unspecified",
    publishingDate: "unspecified",
    relatedEvents: "unspecified",
    relatedPeople: "unspecified",
    season: "unspecified",
    seoTitle: title,
    userId: "unspecified",
  });
  dataLayer.push({
    event: "virtPath",
    virtPath: route,
  });
}

/**
 * GA4 click for "buy on Amazon" style buttons.
 *
 * 'value' : "Buy now on Amazon",
 * 'click_url' : "https://www.amazon.com/xyz...",
 * 'element_position' : "BlocksInteractiveHero",
 * 'method' : "Outbound: TRUE"
 * 'content_type' : "Button Click, Buy now"
 *
 *  * `value` and `clickUrl` are required to be passed, but can be `null` if there's no content.
 * No analytics event will be sent if any of the required parameters are missing.
 */
export function analyticsEcommerceVendorClick({
  value,
  clickUrl,
  elementPosition,
  method,
  contentType,
}: {
  value: string | null;
  clickUrl: string | null;
  elementPosition: string;
  method: "Outbound: TRUE" | "Outbound: FALSE"; // or `string` if gets more complex
  contentType: "Button Click, Buy now"; // or `string` if gets more complex
}) {
  if (!env.NEXT_PUBLIC_HAS_GTM_CONTAINER) {
    return null;
  }

  if (!value || !clickUrl || !elementPosition || !method || !contentType) {
    return null;
  }

  return dataLayer.push({
    event: "ga4Event",
    event_name: "click",
    click: {
      value: value,
      click_url: clickUrl,
      element_position: elementPosition,
      method: method,
      content_type: contentType,
    },
  });
}

/**
 * TODO: This is the same as the analyticsEcommerceVendorClick, apart from the "contentType".
 * Perhaps settings some kind of variant prop to set the contentType automatically?
 *
 * `value` and `clickUrl` are required to be passed, but can be `null` if there's no content.
 * No analytics event will be sent if any of the required parameters are missing.
 */
export function analyticsClick({
  value,
  clickUrl,
  elementPosition,
  method,
  contentType,
}: {
  value: string | null;
  clickUrl: string | null; // Relative URL as parameter, will be converted to full URL in the function
  elementPosition: string | null;
  method: "Outbound: TRUE" | "Outbound: FALSE"; // or `string` if gets more complex
  contentType: string; // Perhaps keep to a set of keywords like "CTA" for consistency
}) {
  if (!env.NEXT_PUBLIC_HAS_GTM_CONTAINER) {
    return null;
  }

  if (!value || !clickUrl || !elementPosition || !method || !contentType) {
    return null;
  }

  return dataLayer.push({
    event: "ga4Event",
    event_name: "click",
    click: {
      value: value,
      click_url: appendHostnameToRelativeUrl(clickUrl),
      element_position: elementPosition,
      method: method,
      content_type: contentType,
    },
  });
}

/**
 *
 * @value The `product name`.
 * @returns
 */
export function analyticsFilter({ value }: { value: string }) {
  if (!env.NEXT_PUBLIC_HAS_GTM_CONTAINER) {
    return null;
  }

  if (!value) {
    return null;
  }

  return dataLayer.push({
    event: "ga4Event",
    event_name: "filter",
    filter: {
      value: value,
      content_type: "Range",
    },
  });
}
