export const FLAGS_API_PREFIX = "https://resources.redbull.com/icons/flags/v2/";

/**
 * Asset "Proxy" Routes
 *
 * These routes are necessary because we have had issues in the past with
 * these upstream sources being geo-blocked in certain countries with a
 * heavy-handed approach to net-neutrality (*cough China cough*).
 *
 * Additionally, PCS eats through bandwidth and this lets us host images
 * cheaper via Akamai.
 *
 * In the front-end, we have to re-write image URLs to use these proxy
 * routes instead of the source URL, so that the browser never makes a
 * direct connection with any of these sources.
 *
 * leave the `https:` off the start of Storyblok URLs (opinionated)
 *
 * TIP: setting an invalid URL is a way to check if any assets are not going through Akamai!
 */
export const CONTENTFUL_IMAGE_BASE_URL = `https://www.redbull.com/energydrink/v1/resources/contentful/images`;
export const CONTENTFUL_VIDEO_BASE_URL = `https://www.redbull.com/energydrink/v1/resources/contentful/videos`;
export const STORYBLOK_IMAGE_BASE_URL = `//www.redbull.com/energydrink/v1/resources/storyblok/images`;
export const STORYBLOK_VIDEO_BASE_URL = `//www.redbull.com/energydrink/v1/resources/storyblok/videos`;
